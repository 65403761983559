 
import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown, Card} from 'react-bootstrap'
import user1 from '../../../assets/images/user/1.jpg'
import CustomToggle from '../../../components/dropdowns'

 
 
import s1 from '../../../assets/images/page-img/s1.jpg'
import s2 from '../../../assets/images/page-img/s2.jpg'
import s3 from '../../../assets/images/page-img/s3.jpg'
 

import { channels, tabData } from './Chatdata'
 

const StatusSidebar = ({setShow}) => {

    const Localdata=JSON.parse(localStorage.getItem('PicPulse_Login'))

    const [activeTab,setActiveTab] = useState('')
 
    const [show1, setShow1] = useState('')
    const [show2, setShow2] = useState('')
    const ChatSidebar =() =>{
        document.getElementsByClassName('scroller')[0].classList.add('show')
    } 
    const ChatSidebarClose =() =>{
        document.getElementsByClassName('scroller')[0].classList.remove('show')
    }
  return (
       <div className="chat-sidebar-channel scroller mt-4 ps-3">
     
   
                <Card>
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title   ">
                                    <h4 className="card-title">Stories</h4>
                                    
                                </div>
                                <Dropdown className="   justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant={`material-symbols-outlined text-dark  cursor-pointer md-18 nav-hide-arrow pe-0 show`}>
                                                                    more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                       
                                                                        <Dropdown.Item className="d-flex align-items-center"   eventKey='status_setting' href="#status_setting" onClick={() => setShow('status_setting')}><i className="material-symbols-outlined md-18 me-1">vpn_key</i>Status privacy</Dropdown.Item>
                                                                        

                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                            </div>
                            <Card.Body>
                                <ul className="media-story list-inline m-0 p-0">
                                    <li className="d-flex mb-3 align-items-center">
                                        <i className="ri-add-line"></i>
                                        <div className="stories-data ms-3">
                                            <h5>Creat Your Story</h5>
                                            <p className="mb-0">time to story</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center active" >
                                         
                                        <img src={s2} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                        

                                           <Nav.Link eventKey='status' onClick={() => setShow('status')} href='#status'>

                                           
                                            <h5>Anna Mull</h5>
                                           </Nav.Link>
                                            <p className="mb-0">1 hour ago</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center">
                                        <img src={s3} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                        <Nav.Link eventKey='status' onClick={() => setShow('status')} href='#status'>
                                            <h5>Ira Membrit</h5>
                                            </Nav.Link> 
                                            <p className="mb-0">4 hour ago</p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <img src={s1} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                        <Nav.Link eventKey='status' onClick={() => setShow('status')} href='#status'>
                                            <h5>Bob Frapples</h5>
                                            </Nav.Link> 

                                            <p className="mb-0">9 hour ago</p>
                                        </div>
                                    </li>
                                </ul>
                                <Link to="#" className="btn btn-primary d-block mt-3">See All</Link>
                            </Card.Body>
                        </Card>




    
            
     
</div> 
  )
}

export default StatusSidebar