import React from 'react'
import { Card, Form } from 'react-bootstrap'

const ChatSetting = () => {
  return (
    <div> <Card>
    <div className="card-header d-flex justify-content-between">
        <div className="header-title">
            <h4 className="card-title">Chats</h4>
        </div>
    </div>
    <Card.Body  className='scroller WebScroller'>
    <div className="stories-data ms-3">
                                          
                                          <h6 className="mb-0">Display</h6>
                                      </div>
        <ul className="media-story list-inline m-0 p-0">
            <li className="d-flex mb-3 align-items-center">
            {/* <i className="ri-account-pin-circle-line"></i> */}
                <div className="stories-data ms-3">
                    <h5>Theme</h5>
                    <p className="mb-0">Dark</p>
                  
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center active">
            {/* <i class="ri-chat-private-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Wallpaper</h5>
                
                </div>
            </li>
            <hr/>
            <div className="stories-data ms-3">
                                          
                                          <h6 className="mb-0">Chat settings</h6>
                                      </div>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-mail-line"></i> */}
            
                <div className="stories-data ms-3">
                    <h5>Enter is send</h5>
                    <Form.Check className="form-switch">
                                            <Form.Check.Input type="checkbox" id="flexSwitchCheckChecked" defaultChecked/>{' '}
                                            <Form.Check.Label>Enter key will send your message</Form.Check.Label>
                                        </Form.Check>
                </div>
            </li>


            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-mail-line"></i> */}
            
                <div className="stories-data ms-3">
                    <h5>Media visibility</h5>
                    <Form.Check className="form-switch">
                                            <Form.Check.Input type="checkbox" id="flexSwitchCheckChecked" defaultChecked/>{' '}
                                            <Form.Check.Label>Show newly downloaded media in your device's gallery</Form.Check.Label>
                                        </Form.Check>
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-user-add-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Font size</h5>
                    <p className="mb-0">Medium</p>
                    
                </div>
            </li>
            <hr />

            <div className="stories-data ms-3">
                                          
                                          <h6 className="mb-0">Archived chats</h6>
                                      </div>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-creative-commons-nd-line"></i> */}
               
                <div className="stories-data ms-3">
                <h5>keep chats archived</h5>
                    <Form.Check className="form-switch">
                                            <Form.Check.Input type="checkbox" id="flexSwitchCheckChecked" defaultChecked/>{' '}
                                            <Form.Check.Label>Archived chats will remain archived when you receive a new message</Form.Check.Label>
                                        </Form.Check>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-arrow-left-right-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Chat backup</h5>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-user-add-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Transfer Chats</h5>
                    
                </div>
            </li>
 

        </ul>

    </Card.Body>
</Card></div>
  )
}

export default ChatSetting