import React from 'react'
import { Card, Form } from 'react-bootstrap'

const NotificationSetting = () => {
  return (
    <div> <Card>
    <div className="card-header d-flex justify-content-between">
        <div className="header-title">
            <h4 className="card-title">Notification</h4>
        </div>
    </div>
    <Card.Body>
 

        <ul className="media-story list-inline m-0 p-0">


        <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-mail-line"></i> */}
            
                <div className="stories-data ms-3">
                    <h5>Conversation tones</h5>
                    <Form.Check className="form-switch">
                                            <Form.Check.Input type="checkbox" id="flexSwitchCheckChecked" defaultChecked/>{' '}
                                            <Form.Check.Label>Play sounds for incoming and outgoing messages.</Form.Check.Label>
                                        </Form.Check>
                </div>
            </li>

            
            <hr/>
            <div className="stories-data ms-3">
                                          
                                          <h6 className="mb-0">Messages</h6>
                                      </div>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-mail-line"></i> */}
            
                <div className="stories-data ms-3">
                    <h5>Notification tone</h5>
                    <p className="mb-0">Default (iphone - Message Tone.mp3)</p>
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-mail-line"></i> */}
            
                <div className="stories-data ms-3">
                    <h5>Vibrate</h5>
                    <p className="mb-0">Default </p>
                </div>
            </li>

            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-mail-line"></i> */}
            
                <div className="stories-data ms-3">
                    <h5>Light</h5>
                    <p className="mb-0">White </p>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-mail-line"></i> */}
            
                <div className="stories-data ms-3">
                    <h5>Use high priority Notifications</h5>
                    <Form.Check className="form-switch">
                                            <Form.Check.Input type="checkbox" id="flexSwitchCheckChecked" defaultChecked/>{' '}
                                            <Form.Check.Label>Show previews of Notification at the top of the screen</Form.Check.Label>
                                        </Form.Check>
                </div>
            </li>
             

        </ul>

    </Card.Body>
</Card></div>
  )
}

export default NotificationSetting