import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap'
import Leaflet from '../../../../components/leaflet';
import Numpad from './NumPad';

const PaymentTab = ({ onHide, show, profile }) => {
   const [correct,setCorrect]=useState(true)
 const [amount,setAmount]=useState('0')
 

    return (
        <Modal size="md" className="fade" id="post-modal" onHide={onHide} show={show}>
           
            <Modal.Body>
           
{correct?
<Container style={ {padding:'20px'}}>

<Row>

<h2>Add your bank account</h2>
<p>to send and receive money on PicPulse. you need to add a bank account </p>
 
                <li className="d-flex mb-3 align-items-center"  >
                <i class="ri-group-line"></i>
                                       
                                        <div className="stories-data ms-3">
                                            <h5><b>Crores of people</b> are already using payment on  PicPulse </h5>
                                          
                                        </div>
                                    </li>
                                    
                                    
                                    <Col lg='12' className='text-center'>
                                    <Button onClick={()=>setCorrect(false)} className='w-100'>Get Started</Button>
                                    </Col>

</Row>
                
</Container>


:
<>

<Col lg="12">
                        <Card className="card-block card-stretch card-height mt-3">
                           <Card.Body className="p-0">
                           <div className="row mb-4">
        <div className="col-12">
          <input type="text" className="amount-input" value={amount} placeholder="Amount" />
        </div>
      </div>
                           </Card.Body>
                        </Card>
                     </Col>
                <Row>
                <div  >
        <Numpad amount={amount}setAmount={setAmount}/>
               </div>
                </Row>
</>}
            </Modal.Body>
        </Modal>
    )
}

export default PaymentTab
