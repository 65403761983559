import React from 'react'
import { Card, Form } from 'react-bootstrap'

const PrivacySetting = () => {
  return (
    <div> <Card>
    <div className="card-header d-flex justify-content-between">
        <div className="header-title">
            <h4 className="card-title">Privacy</h4>
        </div>
    </div>
    <Card.Body  className='scroller WebScroller'>
        <ul className="media-story list-inline m-0 p-0">
 
                                 
                                       
                                        <div className="stories-data ms-3">
                                          
                                            <p className="mb-0">Who can see my personal info</p>
                                        </div>
                               
            <li className="d-flex mb-3 align-items-center">
            {/* <i className="ri-account-pin-circle-line"></i> */}
                <div className="stories-data ms-3">
                    <h5>Last seen and online</h5>
                    <p className="mb-0">My contacts</p>
                  
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center active">
            {/* <i class="ri-chat-private-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Profile photo</h5>
                    <p className="mb-0">My contacts</p>
                
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-mail-line"></i> */}
            
                <div className="stories-data ms-3">
                    <h5>About</h5>
                    <p className="mb-0">My contacts</p>
                     
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-creative-commons-nd-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Status</h5>
                    <p className="mb-0">25 contacts seleted</p>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-arrow-left-right-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Read receipts</h5>
                    <Form.Check className="form-switch">
                                            <Form.Check.Input type="checkbox" id="flexSwitchCheckChecked" defaultChecked/>{' '}
                                            <Form.Check.Label>If turned off,you won't send or receive Read receipts.Read receipts are always sent for group chats.</Form.Check.Label>
                                        </Form.Check>
 
                    
                </div>
            </li>
            <hr/>

            <div className="stories-data ms-3">
                                          
                                            <h6 className="mb-0">Disappearing messages</h6>
                                        </div>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-user-add-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Groups</h5>
                    <p className="mb-0">25 contacts seleted</p>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-user-add-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Live location</h5>
                    <p className="mb-0">None</p>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-user-add-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Calls</h5>
                    <p className="mb-0">Silence unknown callers</p>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-user-add-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Blocked</h5>
                    <p className="mb-0">10</p>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            {/* <i class="ri-user-add-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Advanced</h5>
                    <p className="mb-0">Protect IP address in calls, Disable link previews</p>
                    
                </div>
            </li>
           

        </ul>

    </Card.Body>
</Card></div>
  )
}

export default PrivacySetting