import React from 'react'

 
import { Row, Col, Container, Dropdown, OverlayTrigger, Tooltip, Modal, Form} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../../components/Card'
 
import ShareOffcanvas from '../../../../components/share-offcanvas'
 



const PostCard = ({post,CustomToggle}) => {
  return (
    < > 
    
    
 
        <Card key={post.id} className="card-block card-stretch card-height">
          <Card.Body>
            <div className="user-post-data">
              <div className="d-flex justify-content-between">
                <div className="me-3">
                  <img className="rounded-circle img-fluid" src={post.userImage} alt="" />
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h5 className="mb-0 d-inline-block">{post.userName}</h5>
                      <span className="mb-0 ps-1 d-inline-block">Add New Post</span>
                      <p className="mb-0 text-primary">{post.postTime}</p>
                    </div>
                    <div className="card-post-toolbar">
                      <Dropdown>
                        <Dropdown.Toggle variant="bg-transparent">
                          <span className="material-symbols-outlined">more_horiz</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu m-0 p-0">
                          {post.dropdownItems.map((item, index) => (
                            <Dropdown.Item key={index} href="#">{item.text}</Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <p>{post.postText}</p>
              </div>
              <div className="user-post">
              {!post?.postImages ? 
              <div className="ratio ratio-16x9">
                                            <iframe title="vedio" src={post?.postVideo} ></iframe>
                                        </div>
:

                <div className="d-grid grid-rows-2 grid-flow-col gap-3">
                    
                   { post?.postImages.map((item,index)=>(
<>
                    <div className={`${index === 0 ? 'row-span-2': 'row-span-1'}   row-span-md-1`}>
<img src={item} alt="post1" className="img-fluid rounded w-100" />
</div>
</>
            ))}
         
        </div>

}
              </div>
              <div className="comment-area mt-3">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="like-block position-relative d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="like-data">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle}>
                            <img src="icon1.jpg" className="img-fluid" alt="" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="py-2">
                            <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>}>
                              <img src="icon1.jpg" className="img-fluid me-2" alt="" />
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>}>
                              <img src="icon2.jpg" className="img-fluid me-2" alt="" />
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>}>
                              <img src="icon3.jpg" className="img-fluid me-2" alt="" />
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>}>
                              <img src="icon4.jpg" className="img-fluid me-2" alt="" />
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>}>
                              <img src="icon5.jpg" className="img-fluid me-2" alt="" />
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Sad</Tooltip>}>
                              <img src="icon6.jpg" className="img-fluid me-2" alt="" />
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>}>
                              <img src="icon7.jpg" className="img-fluid me-2" alt="" />
                            </OverlayTrigger>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="total-like-block ms-2 me-3">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} id="post-option">
                            {post.likes} Likes
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {post.dropdownItems.map((item, index) => (
                              <Dropdown.Item key={index} href="#">{item.text}</Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="total-comment-block">
                      <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} id="post-option">
                          {post.comments} Comments
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {post.dropdownItems.map((item, index) => (
                            <Dropdown.Item key={index} href="#">{item.text}</Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  {/* Assuming ShareOffcanvas is defined elsewhere */}
                  <ShareOffcanvas />
                </div>
                <hr />
                <ul className="post-comments list-inline p-0 m-0">
                  {post.commentData.map((comment, index) => (
                    <li key={index} className="mb-2">
                      <div className="d-flex">
                        <div className="user-img">
                          <img src={comment.userImage} alt="user" className="avatar-35 rounded-circle img-fluid" />
                        </div>
                        <div className="comment-data-block ms-3">
                          <h6>{comment.userName}</h6>
                          <p className="mb-0">{comment.commentText}</p>
                          <div className="d-flex flex-wrap align-items-center comment-activity">
                            <Link to="#">like</Link>
                            <Link to="#">reply</Link>
                            <Link to="#">translate</Link>
                            <span>{comment.commentTime}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <Form className="comment-text d-flex align-items-center mt-3">
                  <Form.Control type="text" className="form-control rounded" placeholder="Enter Your Comment" />
                  <div className="comment-attagement d-flex">
                    <Link to="#"><i className="ri-link me-3"></i></Link>
                    <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                    <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                  </div>
                </Form>
              </div>
            </div>
          </Card.Body>
        </Card>
       
    </>
  )
}

export default PostCard