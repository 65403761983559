import React,{useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown ,Modal} from 'react-bootstrap'
 

import Card from '../../../../components/Card'
import CustomToggle from '../../../../components/dropdowns'
import EmojiPicker  from 'emoji-picker-react';
import user1 from '../../../../assets/images/user/1.jpg'
 import user21 from '../../../../assets/images/user/01.jpg'
 import logo from "../../../../assets/images/logo-white.png";

 
import Attachment from '../PicPulseTab/attachment'
import AudioCall from '../PicPulseTab/AudioCall'
import VideoCall from '../VideoCall'
import RemoveChat from '../RemoveChat'
import AudioRec from '../PicPulseTab/AudioRec'
import { tabData } from '../Chatdata'
import LocationTab from '../PicPulseTab/LocationTab'
import PaymentTab from '../PicPulseTab/PaymentTab'

const ChatTab = ({ tab }) => {



  
  const [show, setShow] = useState(tab.eventKey === "first");
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [ModalAttachment, setModalAttachment] = useState(false);
  const handleClose = () => setModalAttachment(false);
  const handleShow = () => setModalAttachment(true);


  const [ModalAudio, setModalAudio] = useState(false);
  const AudioClose = () => setModalAudio(false);
  const AudioShow = () => setModalAudio(true);



  const [ModalVideo, setModalVideo] = useState(false);
  const VideoClose = () => setModalVideo(false);
  const VideoShow = () => setModalVideo(true);



  const [ModalRemove, setModalRemove] = useState(false);
  const RemoveClose = () => setModalRemove(false);
  const RemoveShow = () => setModalRemove(true);




  const [ModalRec, setModalRec] = useState(false);
  const RecClose = () => setModalRec(false);
  const RecShow = () => setModalRec(true);
 // State to track selected emoji

 

 const [Loaction, setLoaction] = useState(false);
 const LocationClose = () => setLoaction(false);
 const LocationShow = () => setLoaction(true);
// State to track selected emoji
const [Payment, setPayment] = useState(false);
const PaymentClose = () => setPayment(false);
const PaymentShow = () => setPayment(true);
// State to track selected emoji


  const [message,setMessage]=useState('')


  const handleChange = (value)=>{
    console.log(message ,'emojiObject')
    setMessage((prevInputValue) => prevInputValue + value);
  }
  const onEmojiClick = (emojiObject) => {
    handleChange(emojiObject?.emoji)
  };
  const userProfileRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
       
      if (userProfileRef.current && !userProfileRef.current.contains(event.target)) {
        setShow2(false);
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [userProfileRef]);
  
  function pushNewMessage(e,newMessage) {
    e.preventDefault()
    
      // tabData[tabIndex].messages.push(newMessage);
      tab.messages.push(newMessage);

      setMessage('')
  }
  return (
    <Tab.Pane eventKey={tab.eventKey} className={`fade ${show ? 'show' : ''}`} id={tab.id} role="tabpanel">
      <div className="chat-head">
        <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
          <div className="d-flex align-items-center">
            <div className="sidebar-toggle">
              <i className="ri-menu-3-line"></i>
            </div>
            <div ref={userProfileRef} className="avatar chat-user-profile m-0 me-3">
              <img loading="lazy" src={tab.userProfile.avatar} alt="avatar" className="avatar-50" onClick={() => setShow2(true)} />
              <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span>
            </div>
            <h5 className="mb-0">{tab.header}</h5>
          </div>
          {/* details   */}
          <div className={`chat-user-detail-popup scroller ${show2 ? 'show' : ''}`}  style={{overflowY:'auto'}}>
           { tab.type !== 'Group'?  <div className="user-profile">
              <Button type="submit" onClick={() => setShow2(false)} variant="close-popup p-3">
                <i className="material-symbols-outlined md-18">close</i>
              </Button>
              <div className="user mb-4 text-center">
                <Link className="avatar m-0" to="">
                  <img loading="lazy" src={tab.userProfile.avatar} alt="avatar" />
                </Link>
                <div className="user-name mt-4">
                  <h4>{tab.userProfile.name}</h4>
                </div>
                <div className="user-desc">
                  <p>{tab.userProfile.location}</p>
                </div>
              </div>
              <hr />
              <div className="chatuser-detail text-left mt-4">
                {tab.userProfile.details.map((detail, idx) => (
                  <React.Fragment key={idx}>
                    <Row>
                      <Col md="6" className="col-6 title">{detail.title}:</Col>
                      <Col md="6" className="col-6 text-right">{detail.value}</Col>
                    </Row>
                    <hr />
                  </React.Fragment>
                ))}
              </div>
            </div>:
            
            
            
            
            <div className="user-profile" >
            <Button type="submit" onClick={() => setShow2(false)} variant="close-popup p-3">
              <i className="material-symbols-outlined md-18">close</i>
            </Button>
            <div className="user mb-4 text-center">
              <Link className="avatar m-0" to="">
                <img loading="lazy" src={tab.userProfile.avatar} alt="avatar" />
              </Link>
              <div className="user-name mt-4">
                <h4>{tab.userProfile.name}</h4>
              </div>
              <div className="user-desc">
                <p>{tab.type} - 224 members</p>
              </div>
            </div>
            <hr />
            <div className="chatuser-detail text-left mt-4">
            <React.Fragment >
                  <Row>
                    <Col md="4" className="col-4 title"> <div>  <Link to="#" className="chat-icon-phone p-3 bg-soft-primary d-flex justify-content-center align-items-center">
              <i className="material-symbols-outlined md-18">phone</i>Voice 
            </Link></div></Col>
            <Col md="4" className="col-4 title"> <div>  <Link to="#" className="chat-icon-phone p-3 bg-soft-primary d-flex justify-content-center align-items-center">
              <i className="material-symbols-outlined md-18">add</i>Add
            </Link></div></Col>
                  
            <Col md="4" className="col-4 title"> <div>  <Link to="#" className="chat-icon-phone p-3 bg-soft-primary d-flex justify-content-center align-items-center">
              <i className="material-symbols-outlined md-18">search</i>Search
            </Link></div></Col>
                  
                  </Row>
                  <hr />
                </React.Fragment>



                <React.Fragment >
                  <Row>
                    <h6 className='text-success'>Add group description</h6>
                 <p>Created by +1 2902 233 233,+1 3223 342 423</p>
                  </Row>
                  <hr />
                </React.Fragment>


                 
                <React.Fragment >
                  <Row>
                  <h6 className='text-success'>Media,link and docs</h6>

                  <Col md="4" className="col-4 title"><img src={user21} alt=''/></Col>
            <Col md="4" className="col-4 title">  <img src={user21} alt=''/></Col>
                  
            <Col md="4" className="col-4 title"> <img src={user21} alt=''/></Col>
                  </Row>
                  <hr />
                </React.Fragment>
                <React.Fragment >
                  <h6 className='text-success'>224 members</h6>
                  {Array.from({ length: 10 }, (_, index) => (
<>
    <Row key={index} className='my-2'>
      <Col md="2" className="col-2 title">
        <img style={{ width:'29px', borderRadius: '25px' }} src={user21} alt='' />
      </Col>
      <Col md="5" className="col-5 title">+1 {index}99 8{index}83 33{index}</Col>
      <Col md="4" className="col-4 title">
        <div>
          <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
            Admin
          </Link>
        </div>
      </Col>
    </Row>
      <hr />
</>
  ))}
   <Row  >
      <h5 className='text-success '>View all(214 more)</h5>
    </Row>
    <Row  >
      <h5 className='text-danger text-decoration-underline'>Exit group</h5>
    </Row>
    <Row  >
      <h5 className='text-danger text-decoration-underline '>report group</h5>
       </Row>
        </React.Fragment>
        </div>
          </div>
            
            }
          </div>
          <div className="chat-header-icons d-flex">
         
         
            <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center" onClick={AudioShow}>
              <i className="material-symbols-outlined md-18">phone</i>
            </Link>
            <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center" onClick={VideoShow}>
              <i className="material-symbols-outlined md-18">videocam</i>
            </Link>
            <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center" onClick={RemoveShow}>
              <i className="material-symbols-outlined md-18">delete</i>
            </Link>
            <Dropdown className="bg-soft-primary d-flex justify-content-center align-items-center" as="span">
              <Dropdown.Toggle as={CustomToggle} variant="material-symbols-outlined cursor-pointer md-18 nav-hide-arrow pe-0 show">
                more_vert
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>Pin to top</Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">delete_outline</i>Delete chat</Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">volume_off</i>Mute</Dropdown.Item>
              
                <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">preview</i>View Contect</Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">mark_chat_unread</i>Mark as unread</Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">thumb_up</i>Add to Favourites</Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">block</i>Block</Dropdown.Item>
              
              
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </header>
      </div>
      <div className="chat-content scroller" >
        <p  >Message and calls are end-to-end encrypted No one outside of this chat, not even PicPulse,can read or listen to them.<a href='#'  className='text-success '> Tap to learn more.</a></p>
        {tab.messages.map((message, idx) => (
    <>      {message.image &&
           <div key={idx} className={`chat d-flex ${message.user === user1 ? 'other-user' : 'chat-left'}`}>
            <div className="chat-user">
              <Link className="avatar m-0" to="">
                <img loading="lazy" src={message.user} alt="avatar" className="avatar-35" />
              </Link>
              <span className="chat-time mt-1">{message.time}</span>
            </div>
            <div className="chat-detail">
              <div className="chat-message" >
                <p  style={{marginRight:'20px'}}>{message.message}
                <div style={{width:'100%'}}>

                <img loading="lazy" src={message.user} alt="avatar" style={{width:'100%'}} />
                </div>
                
                
                <Dropdown className="dropAbcd  justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant={`material-symbols-outlined ${message.user === user1 ? 'text-white':"text-dark"}  cursor-pointer md-18 nav-hide-arrow pe-0 show`}>
                                                                    keyboard_arrow_down
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                       
                                                                        <Dropdown.Item className="d-flex align-items-center"   eventKey='eight' href="#user8" onClick={() => setShow('eight')}><i className="material-symbols-outlined md-18 me-1">reply</i>Reply</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center"   eventKey='eight' href="#user8" onClick={() => setShow('eight')}><i className="material-symbols-outlined md-18 me-1">Forward</i>Forward</Dropdown.Item>
                                                                       { message.user === user1 && <Dropdown.Item className="d-flex align-items-center"     onClick={() =>  setMessage(message.message)}><i className="material-symbols-outlined md-18 me-1">edit</i>Edit</Dropdown.Item>}
                                                                       { message.user === user1 && <Dropdown.Item className="d-flex align-items-center"  onClick={RemoveShow}  ><i className="material-symbols-outlined md-18 me-1">delete</i>Delete</Dropdown.Item>}

                                                                        <Dropdown.Item className="d-flex align-items-center" eventKey='nine' href="#user9" onClick={() => setShow('nine')}><i className="material-symbols-outlined md-18 me-1">add_reaction</i>React</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>pin</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" eventKey='ten' href="#user10" onClick={() => setShow('ten')}><i className="material-symbols-outlined md-18 me-1">star</i>Star</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" eventKey='seven' href="#user7" onClick={() => setShow('seven')}><i className="material-symbols-outlined md-18 me-1">report</i>Report</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="/" onClick={()=>localStorage.removeItem('PicPulse_Login')}><i className="material-symbols-outlined md-18 me-1">Delete </i>Delete</Dropdown.Item>

                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                               {message.user === user1  && <i  style={{position:'absolute',bottom:'0px',right:'0px', fontSize:'20px'}} className="material-symbols-outlined md-18 me-1">done_all</i>}
                
                 </p>
              </div>
            </div>
          </div>
    }


  {message.Link &&
           <div key={idx} className={`chat d-flex ${message.user === user1 ? 'other-user' : 'chat-left'}`}>
            <div className="chat-user">
              <Link className="avatar m-0" to="">
                <img loading="lazy" src={message.user} alt="avatar" className="avatar-35" />
              </Link>
              <span className="chat-time mt-1">{message.time}</span>
            </div>
            <div className="chat-detail">
              <div className="chat-message" >
              <div style={{background: '#e0e0e0', height:'200px' ,overflow:'hidden'}}>

<img loading="lazy" src={logo} alt="avatar" style={{width:'80%'}} />
</div>
                <p  style={{marginRight:'20px'}}>PicPulse by Meander Software is an innovative platform designed to <br></br>enhance online communication and collaboration. 
               
                
                
                <Dropdown className="dropAbcd  justify-content-center align-items-center" as="span">
                                                                    <Dropdown.Toggle as={CustomToggle} variant={`material-symbols-outlined ${message.user === user1 ? 'text-white':"text-dark"}  cursor-pointer md-18 nav-hide-arrow pe-0 show`}>
                                                                    keyboard_arrow_down
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className="dropdown-menu-right">
                                                                       
                                                                        <Dropdown.Item className="d-flex align-items-center"   eventKey='eight' href="#user8" onClick={() => setShow('eight')}><i className="material-symbols-outlined md-18 me-1">reply</i>Reply</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center"   eventKey='eight' href="#user8" onClick={() => setShow('eight')}><i className="material-symbols-outlined md-18 me-1">Forward</i>Forward</Dropdown.Item>
                                                                       { message.user === user1 && <Dropdown.Item className="d-flex align-items-center"     onClick={() =>  setMessage(message.message)}><i className="material-symbols-outlined md-18 me-1">edit</i>Edit</Dropdown.Item>}
                                                                       { message.user === user1 && <Dropdown.Item className="d-flex align-items-center"  onClick={RemoveShow}  ><i className="material-symbols-outlined md-18 me-1">delete</i>Delete</Dropdown.Item>}

                                                                        <Dropdown.Item className="d-flex align-items-center" eventKey='nine' href="#user9" onClick={() => setShow('nine')}><i className="material-symbols-outlined md-18 me-1">add_reaction</i>React</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>pin</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" eventKey='ten' href="#user10" onClick={() => setShow('ten')}><i className="material-symbols-outlined md-18 me-1">star</i>Star</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" eventKey='seven' href="#user7" onClick={() => setShow('seven')}><i className="material-symbols-outlined md-18 me-1">report</i>Report</Dropdown.Item>
                                                                        <Dropdown.Item className="d-flex align-items-center" href="/" onClick={()=>localStorage.removeItem('PicPulse_Login')}><i className="material-symbols-outlined md-18 me-1">Delete </i>Delete</Dropdown.Item>

                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                               {message.user === user1  && <i  style={{position:'absolute',bottom:'0px',right:'0px', fontSize:'20px'}} className="material-symbols-outlined md-18 me-1">done_all</i>}
                
                 </p>

                 <a target='_blank' style={{color:'blue'}} href='https://PicPulse.meander.software/PicPulse' >https://PicPulse.meander.software/PicPulse</a>
              </div>
            </div>
          </div>
    }





{!message.Link && !message.image &&
<div key={idx} className={`chat d-flex ${message.user === user1 ? 'other-user' : 'chat-left'}`}>
<div className="chat-user">
  <Link className="avatar m-0" to="">
    <img loading="lazy" src={message.user} alt="avatar" className="avatar-35" />
  </Link>
  <span className="chat-time mt-1">{message.time}</span>
</div>
<div className="chat-detail">
  <div className="chat-message" >
    <p  style={{marginRight:'20px'}}>{message.message}
    
    
    
    
    <Dropdown className="dropAbcd  justify-content-center align-items-center" as="span">
                                                        <Dropdown.Toggle as={CustomToggle} variant={`material-symbols-outlined ${message.user === user1 ? 'text-white':"text-dark"}  cursor-pointer md-18 nav-hide-arrow pe-0 show`}>
                                                        keyboard_arrow_down
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu-right">
                                                           
                                                            <Dropdown.Item className="d-flex align-items-center"   eventKey='eight' href="#user8" onClick={() => setShow('eight')}><i className="material-symbols-outlined md-18 me-1">reply</i>Reply</Dropdown.Item>
                                                            <Dropdown.Item className="d-flex align-items-center"   eventKey='eight' href="#user8" onClick={() => setShow('eight')}><i className="material-symbols-outlined md-18 me-1">Forward</i>Forward</Dropdown.Item>
                                                           { message.user === user1 && <Dropdown.Item className="d-flex align-items-center"     onClick={() =>  setMessage(message.message)}><i className="material-symbols-outlined md-18 me-1">edit</i>Edit</Dropdown.Item>}
                                                           { message.user === user1 && <Dropdown.Item className="d-flex align-items-center"  onClick={RemoveShow}  ><i className="material-symbols-outlined md-18 me-1">delete</i>Delete</Dropdown.Item>}

                                                            <Dropdown.Item className="d-flex align-items-center" eventKey='nine' href="#user9" onClick={() => setShow('nine')}><i className="material-symbols-outlined md-18 me-1">add_reaction</i>React</Dropdown.Item>
                                                            <Dropdown.Item className="d-flex align-items-center" href="#"><i className="material-symbols-outlined md-18 me-1">push_pin</i>pin</Dropdown.Item>
                                                            <Dropdown.Item className="d-flex align-items-center" eventKey='ten' href="#user10" onClick={() => setShow('ten')}><i className="material-symbols-outlined md-18 me-1">star</i>Star</Dropdown.Item>
                                                            <Dropdown.Item className="d-flex align-items-center" eventKey='seven' href="#user7" onClick={() => setShow('seven')}><i className="material-symbols-outlined md-18 me-1">report</i>Report</Dropdown.Item>
                                                            <Dropdown.Item className="d-flex align-items-center" href="/" onClick={()=>localStorage.removeItem('PicPulse_Login')}><i className="material-symbols-outlined md-18 me-1">Delete </i>Delete</Dropdown.Item>

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                   {message.user === user1  && <i  style={{position:'absolute',bottom:'0px',right:'0px', fontSize:'20px'}} className="material-symbols-outlined md-18 me-1">done_all</i>}
    
     </p>
  </div>
</div>
</div>}

</>
        ))}
      </div>
      <div className="chat-footer p-3 bg-white" style={{position:'relative'}}>
  {/* Emoji Picker with custom style */}
{show3 && <div style={{position: 'absolute', bottom: '70px', left: '10px', zIndex: '9999',}}>
<EmojiPicker
emojiStyle='apple'
  onEmojiClick={onEmojiClick}
  pickerStyle={{borderRadius: '5px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
/></div> }
  {/* Chat input */}
  <Form className="d-flex align-items-center" action="#">
    <div className="chat-attachment d-flex">
      <Link to="#"><i className="far fa-smile pe-3" aria-hidden="true" onClick={()=>setShow3(!show3)}></i></Link>
      <Link to="#"><i className="fa fa-paperclip pe-3" aria-hidden="true" onClick={handleShow}></i></Link>
 


    </div>
 
    <Form.Control type="text" className="me-3" placeholder="Type your message"  value={message} onChange={(e)=>setMessage(e.target.value)}/>
    <Link to="#"><i className="fa fa-microphone pe-3" aria-hidden="true" onClick={RecShow}></i></Link>
   
    <Button type="submit" variant="primary d-flex align-items-center" onClick={(e)=>pushNewMessage(e,{
      user: user1,
      time: "10:00",
      message: message
    })}>
      <i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1">Send</span>
    </Button>
  </Form>
</div>

<Attachment onHide={handleClose} show={ModalAttachment} Fucntion={{LocationShow,PaymentShow}}/>
<AudioCall onHide={AudioClose} show={ModalAudio} profile={tab.userProfile}/>
<VideoCall onHide={VideoClose} show={ModalVideo} profile={tab.userProfile}/>
<RemoveChat onHide={RemoveClose} show={ModalRemove} profile={tab.userProfile}/>
<AudioRec onHide={RecClose} show={ModalRec} profile={tab.userProfile}/>
<PaymentTab onHide={PaymentClose} show={Payment} profile={tab.userProfile}/>
<LocationTab onHide={LocationClose} show={Loaction} profile={tab.userProfile}/>



{/* <Modal size="lg" className="fade" id="post-modal" onHide={handleClose} show={ModalAttachment} >
                                    <Modal.Header  className="d-flex justify-content-between">
                                        <Modal.Title id="post-modalLabel">Send message</Modal.Title>
                                        <Link to="#" className="lh-1" onClick={handleClose} >
                                            <span className="material-symbols-outlined">close</span>
                                        </Link>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="user-img">
                                                <img src={user1} alt="user1" className="avatar-60 rounded-circle img-fluid"/>
                                            </div>
                                            <form className="post-text ms-3 w-100 "  data-bs-toggle="modal" data-bs-target="#post-modal">
                                            <input type="text" className="form-control rounded" placeholder="Write something here..." style={{border:"none"}}/>
                                        </form>
                                        </div>
                                        <hr/>
                                        <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                                            <li className="col-md-6 mb-3">
                                                <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link>
                                                <img src={img1} alt="icon" className="img-fluid"/> Photo/Video</div>
                                            </li>
                                            <li className="col-md-6 mb-3">
                                                <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link>
                                                <img src={img2} alt="icon" className="img-fluid"/>Camera</div>
                                            </li>
                                            <li className="col-md-6 mb-3">
                                                <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link>
                                                <img src={img3} alt="icon" className="img-fluid"/>Location</div>
                                            </li>
                                            <li className="col-md-6 mb-3">
                                                <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link>
                                                <img src={img4} alt="icon" className="img-fluid"/>Content</div>
                                            </li>
                                            <li className="col-md-6 mb-3">
                                                <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link>
                                                <img src={img5} alt="icon" className="img-fluid"/> Document</div>
                                            </li>
                                            <li className="col-md-6 mb-3">
                                                <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link>
                                                <img src={img6} alt="icon" className="img-fluid"/> Audio</div>
                                            </li>
                                            <li className="col-md-6 mb-3">
                                                <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link>
                                                <img src={img7} alt="icon" className="img-fluid"/>Poll</div>
                                            </li>
                                            <li className="col-md-6 mb-3">
                                                <div className="bg-soft-primary rounded p-2 pointer me-3"><Link to="#"></Link>
                                                <img src={img8} alt="icon" className="img-fluid"/>Payment</div>
                                            </li>
                                        </ul>
                                        <hr/>
                                        <div className="other-option">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                <div className="user-img me-3">
                                                    <img src={user1} alt="user1" className="avatar-60 rounded-circle img-fluid"/>
                                                </div>
                                                <h6>Your Story</h6>
                                                </div>
                                                <div className="card-post-toolbar">
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle} role="button">
                                                        <span className="btn btn-primary">Friend</span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className=" m-0 p-0">
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top">
                                                                <i className="ri-save-line h4"></i>
                                                                <div className="data ms-2">
                                                                    <h6>Public</h6>
                                                                    <p className="mb-0">Anyone on or off Facebook</p>
                                                                </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="p-3" to="#">
                                                                <div className="d-flex align-items-top">
                                                                <i className="ri-close-circle-line h4"></i>
                                                                <div className="data ms-2">
                                                                    <h6>Friends</h6>
                                                                    <p className="mb-0">Your Friend on facebook</p>
                                                                </div>
                                                                </div>
                                                            </Dropdown.Item>        
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top">
                                                                <i className="ri-user-unfollow-line h4"></i>
                                                                <div className="data ms-2">
                                                                    <h6>Friends except</h6>
                                                                    <p className="mb-0">Don't show to some friends</p>
                                                                </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top">
                                                                <i className="ri-notification-line h4"></i>
                                                                <div className="data ms-2">
                                                                    <h6>Only Me</h6>
                                                                    <p className="mb-0">Only me</p>
                                                                </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        <button type="submit" className="btn btn-primary d-block w-100 mt-3">Send message</button>
                                    </Modal.Body>
                                </Modal> */}
</Tab.Pane>



  );
};


export default ChatTab