import { configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import AuthSlice from './Auth/Slice'
import Roleslice from './Role/Slice'


export const store = configureStore({
  reducer: {
    setting: settingReducer,
    auth:AuthSlice,
    role:Roleslice
  }
});
