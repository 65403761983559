 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setloaderFalse } from './store/Auth/Slice';
 
 

export const handleToast = (message, type) => (dispatch) => {
//   dispatch(showToast({ message, type }));
console.log(message,'message');
  switch (type) {
    case 'success':
      toast.success(message);
      setloaderFalse()
      break;
    case 'error':
      toast.error(message);
      setloaderFalse()
      break;
    case 'warning':
      toast.warn(message);
      setloaderFalse()
      break;
    default:
      toast.info(message);
      setloaderFalse()
      break;
  }
};
