 
 
 
import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown} from 'react-bootstrap'
import QR from "../../../../assets/images/QR.png"
 

//img
import user1 from '../../../../assets/images/user/1.jpg'
 

 

 
const ProfileShare = ({show1, Localdata,setShow1, ChatSidebarClose}) => {
 
  return (
     <div id="user-detail-popup" className={`scroller ${show1 === 'true' ? 'show' : '' }`}>
    <div className="user-profile">
        <Button type="submit" onClick={ChatSidebarClose} variant=" close-popup p-3"><i className="material-symbols-outlined md-18" onClick={() => setShow1('false')}>close</i></Button>
        <div className="user text-center mb-4">
            <Link className="avatar m-0" to="">
                <img loading="lazy" src={user1} alt="avatar"/>
            </Link>
            <div className="user-name mt-4">
                <h4 className="text-center">{Localdata?.fullname}</h4>
            </div>
            <div className="user-desc">
                <p className="text-center">Web Designer</p>
            </div>
        </div>
       
        <hr/>
      <div className='text-center'>
      <img src={QR} alt="QR.png" width='150px'/>
      </div>
      <div className="user-desc">
                <p className="text-center">Scan QR code for profile sharing</p>
            </div>
    </div>
</div>
  )
}

export default ProfileShare