import React from 'react'
import { Card } from 'react-bootstrap'

const AccountSetting = () => {
  return (
    <div> <Card>
    <div className="card-header d-flex justify-content-between">
        <div className="header-title">
            <h4 className="card-title">Accounts</h4>
        </div>
    </div>
    <Card.Body>
        <ul className="media-story list-inline m-0 p-0">
            <li className="d-flex mb-3 align-items-center">
            <i className="ri-account-pin-circle-line"></i>
                <div className="stories-data ms-3">
                    <h5>Security notification</h5>
                  
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center active">
            <i class="ri-chat-private-line"></i>
               
                <div className="stories-data ms-3">
                    <h5>Passkeys</h5>
                
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            <i class="ri-mail-line"></i>
            
                <div className="stories-data ms-3">
                    <h5>Email address</h5>
                     
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            <i class="ri-creative-commons-nd-line"></i>
               
                <div className="stories-data ms-3">
                    <h5>Two-step verification</h5>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            <i class="ri-arrow-left-right-line"></i>
               
                <div className="stories-data ms-3">
                    <h5>Change number</h5>
                    
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center">
            <i class="ri-user-add-line"></i>
               
                <div className="stories-data ms-3">
                    <h5>Add account</h5>
                    
                </div>
            </li>

            <li className="d-flex align-items-center">
            <i class="ri-chat-delete-line"></i>
               
                <div className="stories-data ms-3">
                    <h5>Delete account</h5>
                     
                </div>
            </li>

        </ul>

    </Card.Body>
</Card></div>
  )
}

export default AccountSetting