import React from "react";
import ChatLayout from "../layouts/dashboard/chat-layout";
import Chat from "../views/dashboard/app/chat";
import WithAuth from "../ProtectedRoutes";

export const ChatRouter = [

  {
    path: "/",
    element: <WithAuth   />, // Protect the entire Default layout
    children: [
      {
        path: "PicPulse",
        element: <ChatLayout />,
        children: [
          {
            path: "",
            element: <Chat />,
          },
        ],
      },
    ],
  },
   
];
