import React,{useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown ,Modal} from 'react-bootstrap'
 

import Card from '../../../../components/Card'
import CustomToggle from '../../../../components/dropdowns'
import EmojiPicker  from 'emoji-picker-react';
import user1 from '../../../../assets/images/user/1.jpg'
 import user21 from '../../../../assets/images/user/01.jpg'

 

const NewGroupsTab = ({ show }) => {
 
  
 
   
 
  return (
    <Tab.Pane eventKey='eight' className={`fade ${show ? 'show' : ''}`} id='user8' role="tabpanel">
    <div className="chat-head">
      <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
        <div className="d-flex align-items-center">
          <div className="sidebar-toggle">
            <i className="ri-menu-3-line"></i>
          </div>
          <div className="avatar chat-user-profile m-0 me-3">
            <img loading="lazy" src={user1} alt="avatar" className="avatar-50"   />
            <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span>
          </div>
          <h5 className="mb-0">New Group</h5>
        </div>
         
      </header>


      <div className='scroller WebScroller90'  >
                  <h6 className='text-success'>224 members</h6>
                  {Array.from({ length: 10 }, (_, index) => (
<>
    <Row key={index} className='my-2'>
      <Col md="2" className="col-2 title">
        <img style={{ width:'29px', borderRadius: '25px' }} src={user21} alt='' />
      </Col>
      <Col md="5" className="col-5 title">+1 {index}99 8{index}83 33{index}</Col>
      <Col md="1" className="col-4 title">
        <div>
          <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
            Add
          </Link>
        </div>
      </Col>
    </Row>
      <hr />
</>
  ))}
   <Row  >
      <h5 className='text-success '>View all(214 more)</h5>
    </Row>
    <div className='empty20 ' >
     
     </div>
        </div>

    </div>

     
</Tab.Pane>
  );
};


export default NewGroupsTab