import React,{useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown ,Modal} from 'react-bootstrap'
 

import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import EmojiPicker  from 'emoji-picker-react';
import user1 from '../../../assets/images/user/1.jpg'
 import user21 from '../../../assets/images/user/01.jpg'



//image
 
import user01 from '../../../assets/images/user/01.jpg'
import user2 from '../../../assets/images/user/02.jpg'
import user3 from '../../../assets/images/user/03.jpg'
import user4 from '../../../assets/images/user/04.jpg'
import img1 from '../../../assets/images/small/07.png'
import img2 from '../../../assets/images/small/08.png'
import img3 from '../../../assets/images/small/09.png'
import img4 from '../../../assets/images/small/10.png'
import img5 from '../../../assets/images/small/11.png'
import img6 from '../../../assets/images/small/12.png'
import img7 from '../../../assets/images/small/13.png'
import img8 from '../../../assets/images/small/14.png'
import p1 from '../../../assets/images/page-img/p1.jpg'
import s1 from '../../../assets/images/page-img/s1.jpg'
import s2 from '../../../assets/images/page-img/s2.jpg'
import s3 from '../../../assets/images/page-img/s3.jpg'
import s4 from '../../../assets/images/page-img/s4.jpg'
import s5 from '../../../assets/images/page-img/s5.jpg'
import p2 from '../../../assets/images/page-img/p2.jpg'
import p3 from '../../../assets/images/page-img/p3.jpg'
import p4 from '../../../assets/images/page-img/p4.jpg'
import p5 from '../../../assets/images/page-img/p5.jpg'
import img42 from '../../../assets/images/page-img/42.png'
import icon1 from '../../../assets/images/icon/01.png'
import icon2 from '../../../assets/images/icon/02.png'
import icon3 from '../../../assets/images/icon/03.png'
import icon4 from '../../../assets/images/icon/04.png'
import icon5 from '../../../assets/images/icon/05.png'
import icon6 from '../../../assets/images/icon/06.png'
import icon7 from '../../../assets/images/icon/07.png'
import img9 from '../../../assets/images/small/img-1.jpg'
import img10 from '../../../assets/images/small/img-2.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import Attachment from './PicPulseTab/attachment'
import AudioCall from './PicPulseTab/AudioCall'
import VideoCall from './VideoCall'
import RemoveChat from './RemoveChat'

const StatusSetting = ({ show }) => {
 
  
 
   
 
  return (
    <Tab.Pane eventKey='status_setting' className={`fade ${show ? 'show' : ''}`} id='status_setting' role="tabpanel">
    <div className="chat-head">
      <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
        <div className="d-flex align-items-center">
          <div className="sidebar-toggle">
            <i className="ri-menu-3-line"></i>
          </div>
          <div className="avatar chat-user-profile m-0 me-3">
          <Nav.Link    href='/PicPulse'>

                                           
          <i className="material-symbols-outlined iconSize30  text-dark">arrow_back</i>
</Nav.Link>

           
          </div>
          <h5 className="mb-0">Status privacy</h5>
        </div>
         
      </header>


      <React.Fragment >
      <div> <Card>
    <div className="card-header d-flex justify-content-between">
        <div className="header-title">
            <h6 className="card-title">Who can see my status updates</h6>
        </div>
    </div>
    <Card.Body>

    <Form id="form-wizard3" className="text-start">
    <Col md="12">
                                                            <Form.Group className="form-group">
                                                            <Form.Check className="form-check">
                                                                
                                                                    <Form.Check className="form-check form-check-inline">
                                                                        <Form.Check.Input type="radio" className="form-check-input" checked name="customRadio" id="customRadio1"/>
                                                                        <Form.Check.Label> My contacts</Form.Check.Label>
                                                                    </Form.Check>
                                                                    </Form.Check>
                                                                    
                                                                
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                               
                                                     
                                                            <Form.Check className="form-check">
                                                                    <Form.Check className="form-check form-check-inline">
                                                                        <Form.Check.Input type="radio" className="form-check-input" name="customRadio" id="customRadio1"/>
                                                                        <Form.Check.Label> My contacts except...</Form.Check.Label>
                                                                    </Form.Check>
                                                                    <Form.Check className="form-check form-check-inline">
                                                                       
                                                                        <Form.Check.Label className='text-success '>5 excluded</Form.Check.Label>
                                                                    </Form.Check>
                                                                    </Form.Check>
                                                            </Form.Group>
                                                        </Col>


                                                        <Col md="12">
                                                            <Form.Group className="form-group">
                                                               
                                                                <Form.Check className="form-check">
                                                                    <Form.Check className="form-check form-check-inline">
                                                                        <Form.Check.Input type="radio" className="form-check-input" name="customRadio" id="customRadio1"/>
                                                                        <Form.Check.Label> Only share with...</Form.Check.Label>
                                                                    </Form.Check>
                                                                    <Form.Check className="form-check form-check-inline">
                                                                        
                                                                        <Form.Check.Label className='text-success '> 17 excluded</Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Check>
                                                            </Form.Group>
                                                        </Col>
                                                        <Form.Label >Changes to your privacy settings won't affect status updates that you've sent already</Form.Label>
</Form>
        
    </Card.Body>
</Card></div>
        </React.Fragment>

    </div>

     
</Tab.Pane>
  );
};


export default StatusSetting