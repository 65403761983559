import axios from "axios";
import { Active_Mode, BaseUrl } from "../../config";
 
 

export const LoginAuth = async (data) => {

    try {
        const resoponse = await axios.post(`${BaseUrl}User/login?mode=${Active_Mode}`,data)
      
   localStorage.setItem("PicPulse_Login",JSON.stringify(resoponse.data.data))
       
        return resoponse
    } catch (error) {
 
      throw error;    
    }
  };


  export const LoginAuthWithPhone = async (data) => {

    try {
        const resoponse = await axios.post(`${BaseUrl}User/loginwithemailorcontact?mode=${Active_Mode}`,data)
      
   localStorage.setItem("PicPulse_Login",JSON.stringify(resoponse.data.data))
       
        return resoponse
    } catch (error) {
 
      throw error;    
    }
  };
export const CreateAuth = async (data) => {

  try {
      const resoponse = await axios.post(`${BaseUrl}User/create`,data)
    
     
      return resoponse
  } catch (error) {
    console.log(error)
    throw error;    
  }
};