import React from 'react'
import { Card, Form } from 'react-bootstrap'

const HelpSetting = () => {
  return (
    <div> <Card>
    <div className="card-header d-flex justify-content-between">
        <div className="header-title">
            <h4 className="card-title">Help</h4>
        </div>
    </div>
    <Card.Body>
 
        <ul className="media-story list-inline m-0 p-0">
            <li className="d-flex mb-3 align-items-center">
            {/* <i className="ri-account-pin-circle-line"></i> */}
                <div className="stories-data ms-3">
                    <h5>Help centre</h5>
                    <p className="mb-0">Get help,contact us</p>
                  
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center active">
            {/* <i class="ri-chat-private-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Terms and Privacy Policy </h5>
                
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center active">
            {/* <i class="ri-chat-private-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Channel reports</h5>
                
                </div>
            </li>
            <li className="d-flex mb-3 align-items-center active">
            {/* <i class="ri-chat-private-line"></i> */}
               
                <div className="stories-data ms-3">
                    <h5>Web info</h5>
                
                </div>
            </li>
             
 

        </ul>

    </Card.Body>
</Card></div>
  )
}

export default HelpSetting