
//image
import user1 from '../../assets/images/user/1.jpg'
import user01 from '../../assets/images/user/01.jpg'
import user2 from '../../assets/images/user/02.jpg'
import user3 from '../../assets/images/user/03.jpg'
import user4 from '../../assets/images/user/04.jpg'
import img1 from '../../assets/images/small/07.png'
import img2 from '../../assets/images/small/08.png'
import img3 from '../../assets/images/small/09.png'
import img4 from '../../assets/images/small/10.png'
import img5 from '../../assets/images/small/11.png'
import img6 from '../../assets/images/small/12.png'
import img7 from '../../assets/images/small/13.png'
import img8 from '../../assets/images/small/14.png'
import p1 from '../../assets/images/page-img/p1.jpg'
import s1 from '../../assets/images/page-img/s1.jpg'
import s2 from '../../assets/images/page-img/s2.jpg'
import s3 from '../../assets/images/page-img/s3.jpg'
import s4 from '../../assets/images/page-img/s4.jpg'
import s5 from '../../assets/images/page-img/s5.jpg'
import p2 from '../../assets/images/page-img/p2.jpg'
import p3 from '../../assets/images/page-img/p3.jpg'
import p4 from '../../assets/images/page-img/p4.jpg'
import p5 from '../../assets/images/page-img/p5.jpg'
import img42 from '../../assets/images/page-img/42.png'
import icon1 from '../../assets/images/icon/01.png'
import icon2 from '../../assets/images/icon/02.png'
import icon3 from '../../assets/images/icon/03.png'
import icon4 from '../../assets/images/icon/04.png'
import icon5 from '../../assets/images/icon/05.png'
import icon6 from '../../assets/images/icon/06.png'
import icon7 from '../../assets/images/icon/07.png'
import img9 from '../../assets/images/small/img-1.jpg'
import img10 from '../../assets/images/small/img-2.jpg'


export const posts = [
    {
      id: 1,
      userImage: user01,
      userName: 'Anna Sthesia',
      postTime: 'Just Now',
      postText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus',
      postImages: [p2, p1, p3],
      likes: 140,
      comments: 20,
      commentData: [
        {
          userImage: user2,
          userName: 'Monty Carlo',
          commentText: 'Lorem ipsum dolor sit amet',
          commentTime: '5 min'
        },
        {
          userImage: user3,
          userName: 'Paul Molive',
          commentText: 'Lorem ipsum dolor sit amet',
          commentTime: '5 min'
        }
      ],
      dropdownItems: [
        { text: 'Max Emum' },
        { text: 'Bill Yerds' },
        { text: 'Hap E. Birthday' },
        { text: 'Tara Misu' },
        { text: 'Midge Itz' },
        { text: 'Sal Vidge' },
        { text: 'Other' }
      ]
    },
    {
        id: 3,
        userImage: user3,
        userName: 'Barb Ackue',
        postTime: '1 hour ago',
        postText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus',
        postImages: [p4],
        likes: 140,
        comments: 20,
        commentData: [
          {
            userImage: user2,
            userName: 'Monty Carlo',
            commentText: 'Lorem ipsum dolor sit amet',
            commentTime: '5 min'
          },
          {
            userImage: user3,
            userName: 'Paul Molive',
            commentText: 'Lorem ipsum dolor sit amet',
            commentTime: '5 min'
          }
        ],
        dropdownItems: [
          { text: 'Save Post', icon: 'ri-save-line' },
          { text: 'Hide Post', icon: 'ri-close-circle-line' },
          { text: 'Unfollow User', icon: 'ri-user-unfollow-line' },
          { text: 'Notifications', icon: 'ri-notification-line' }
        ]
      },
      {
        id: 2,
        userImage: user4,
        userName: 'Ira Membrit',
        postTime: '6 hours ago',
        postText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus',
        postImages: [p1], // No additional images provided in the example
        likes: 140,
        comments: 20,
        commentData: [
          {
            userImage: user2,
            userName: 'Monty Carlo',
            commentText: 'Lorem ipsum dolor sit amet',
            commentTime: '5 min'
          },
          {
            userImage: user3,
            userName: 'Paul Molive',
            commentText: 'Lorem ipsum dolor sit amet',
            commentTime: '5 min'
          }
        ],
        dropdownItems: [
          { text: 'Save Post', icon: 'ri-save-line' },
          { text: 'Hide Post', icon: 'ri-close-circle-line' },
          { text: 'Unfollow User', icon: 'ri-user-unfollow-line' },
          { text: 'Notifications', icon: 'ri-notification-line' }
        ]
      },
      {
        id: 32,
        userImage: user1,
        userName: 'Bni Cyst',
        postTime: '3 days ago',
        postText: 'Changed Profile Picture',
        postImages: [p5], // Assuming p5 is the image for this post
        likes: 140,
        comments: 20,
        commentData: [
          {
            userImage: user2,
            userName: 'Monty Carlo',
            commentText: 'Lorem ipsum dolor sit amet',
            commentTime: '5 min'
          },
          {
            userImage: user3,
            userName: 'Paul Molive',
            commentText: 'Lorem ipsum dolor sit amet',
            commentTime: '5 min'
          }
        ],
        dropdownItems: [
          { text: 'Save Post', icon: 'ri-save-line' },
          { text: 'Hide Post', icon: 'ri-close-circle-line' },
          { text: 'Unfollow User', icon: 'ri-user-unfollow-line' },
          { text: 'Notifications', icon: 'ri-notification-line' }
        ]
      },
      {
        id: 4,
        userImage: user2,
        userName: 'Paige Turner',
        postTime: '1 day ago',
        postText: 'Added New Video in his Timeline',
        postContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus.',
        postVideo: 'https://www.youtube.com/embed/j_GsIanLxZk?rel=0',
        likes: 140,
        comments: 20,
        commentData: [
          {
            userImage: user2,
            userName: 'Monty Carlo',
            commentText: 'Lorem ipsum dolor sit amet',
            commentTime: '5 min'
          },
          {
            userImage: user3,
            userName: 'Paul Molive',
            commentText: 'Lorem ipsum dolor sit amet',
            commentTime: '5 min'
          }
        ],
        dropdownItems: [
          { text: 'Save Post', icon: 'ri-save-line' },
          { text: 'Hide Post', icon: 'ri-close-circle-line' },
          { text: 'Unfollow User', icon: 'ri-user-unfollow-line' },
          { text: 'Notifications', icon: 'ri-notification-line' }
        ]
      }
  ];