import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import './MicrophoneButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';


const AudioRec = ({ onHide, show, profile }) => {
   

    const endCall = () => {
        
        onHide();
    };

    const [active, setActive] = useState(true);

    const toggleActive = () => {
      setActive(!active);
    };
    const [timing, setTiming] = useState('Ringing.....')
    const [timer, setTimer] = useState(50)

    useEffect(() => {
        let timerInterval;
        if (timing === 'Ringing.....') {
            const ringingTimeout = setTimeout(() => {
                setTiming('active');
                setTimer(0);
            }, 5000);

            return () => clearTimeout(ringingTimeout);
        } else if (timing === 'active') {
            timerInterval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }

        return () => clearInterval(timerInterval);
    }, [timing]);

   
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    return (
        <Modal size="md" className="fade" id="post-modal" onHide={onHide} show={show}>
            <Modal.Header className="d-flex justify-content-center">
                <div className="user mb-4 text-center">
                 
                    <div className="user-name mt-4">
                        <h4>Recording</h4>
                    </div>
                   
                </div>
            </Modal.Header>
            <Modal.Body>
            <div className="mic-container">
      <div className={`circle ${active ? 'active' : ''}`} onClick={toggleActive}>
        <FontAwesomeIcon icon={faMicrophone} />
      </div>
    
    </div>
    <br></br>
    <div className='text-center'> 


<p>{ `${formatTime(timer)} - Sec`}</p>
</div>
                
            </Modal.Body>
            <Modal.Footer>
            <Button type="submit" variant="danger d-flex align-items-center ">
      <i className="fa fa-times" aria-hidden="true"></i><span className="d-none d-lg-block ms-1" onClick={endCall} >Cancel</span>
    </Button>
            <Button type="submit" variant="primary d-flex align-items-center">
      <i className="far fa-paper-plane" aria-hidden="true"></i><span className="d-none d-lg-block ms-1" onClick={endCall}>Send</span>
    </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AudioRec
