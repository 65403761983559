import React, { useState, useContext} from 'react'

//router
import { Link, useLocation } from 'react-router-dom'

//react-bootstrap
import { Accordion, useAccordionButton, AccordionContext, Tooltip,OverlayTrigger ,
    Dropdown,
    Nav,
    Form,
    Card,
    Container,
    Image,
    Modal,
  } from "react-bootstrap";
 
  
  //image 
  import user1 from "../../../../assets/images/user/1.jpg";
  import user2 from "../../../../assets/images/user/02.jpg";
  import user3 from "../../../../assets/images/user/03.jpg";
  import user4 from "../../../../assets/images/user/04.jpg";
  import user5 from "../../../../assets/images/user/05.jpg";
  import user6 from "../../../../assets/images/page-img/19.jpg";
  import user7 from "../../../../assets/images/page-img/18.jpg";
  import user8 from "../../../../assets/images/page-img/20.jpg";
  import user9 from "../../../../assets/images/page-img/21.jpg";
  import user10 from "../../../../assets/images/page-img/22.jpg";
  import user11 from "../../../../assets/images/page-img/23.jpg";
  import user12 from "../../../../assets/images/page-img/24.jpg";
  import user13 from "../../../../assets/images/page-img/09.jpg";
  import user14 from "../../../../assets/images/page-img/03.jpg";
  import user15 from "../../../../assets/images/page-img/02.jpg";
  import user16 from "../../../../assets/images/page-img/01.jpg";
import CreatePost from '../../../../views/dashboard/app/PicPulseModal/CreatePost';
 


function CustomToggle({ children, eventKey, onClick }) {

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({ state: !active, eventKey: eventKey }));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Link to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} className="nav-link" role="button" onClick={(e) => {
            decoratedOnClick(isCurrentEventKey)
        }}>
            {children}
        </Link>
    );
}

const VerticalNav = React.memo(() => {

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    const [activeMenu, setActiveMenu] = useState(false)
    const [active, setActive]= useState('')
    //location
    let location = useLocation();
    // console.log(document);


    return (
        <React.Fragment>
            <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
               
                <li  className={`${location.pathname === '/' ? 'active' : ''} nav-item `}>
                    <Link className={`${location.pathname === '/' ? 'active' : ''} nav-link `} aria-current="page" to="/">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Home</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                home
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Home</span>
                    </Link>
                </li>
                <Accordion.Item as="li" eventKey="profile-menu" bsPrefix={`nav-item ${active === 'profile' ? 'active' : '' } `} onClick={() => setActive('profile')} >
                <Link className={`${location.pathname === '/dashboard/app/profile' ? 'active' : ''} nav-link`} to="" onClick={handleShow}>
                                    
                                    <i className="icon material-symbols-outlined">
                                search
                            </i>
                                    <OverlayTrigger placement="right" overlay={<Tooltip>Search</Tooltip>}>
                                     <i className="sidenav-mini-icon"> P </i>
                                    </OverlayTrigger>
                                    <span className="item-name"> Search </span>
                                </Link>
                   
                </Accordion.Item>
                <Accordion.Item as="li" eventKey="profile-menu" bsPrefix={`nav-item ${active === 'profile' ? 'active' : '' } `} onClick={() => setActive('profile')} >
                <Link className={`${location.pathname === '/explore' ? 'active' : ''} nav-link`} to="/explore">
                                    
                                    <i className="icon material-symbols-outlined">
                                    Explore
                            </i>
                                    <OverlayTrigger placement="right" overlay={<Tooltip>Explore</Tooltip>}>
                                     <i className="sidenav-mini-icon"> P </i>
                                    </OverlayTrigger>
                                    <span className="item-name"> Explore </span>
                                </Link>
                   
                </Accordion.Item>



                <Accordion.Item as="li" eventKey="profile-menu" bsPrefix={`nav-item ${active === 'profile' ? 'active' : '' } `} onClick={() => setActive('profile')} >
                <Link className={`${location.pathname === '/video' ? 'active' : ''} nav-link`} to="/video">
                                  
                                    <i className="icon material-symbols-outlined">
                                    play_circle
                            </i>
                                    <OverlayTrigger placement="right" overlay={<Tooltip>Videos</Tooltip>}>
                                     <i className="sidenav-mini-icon"> P </i>
                                    </OverlayTrigger>
                                    <span className="item-name"> Shorts </span>
                                </Link>
                </Accordion.Item>




                <Accordion.Item as="li" eventKey="profile-menu" bsPrefix={`nav-item ${active === 'profile' ? 'active' : '' } `} onClick={() => setActive('profile')} >
                <Link className={`${location.pathname === '/picpluse/chat' ? 'active' : ''} nav-link `} aria-current="page" to="/picpluse/chat"  >
                        <OverlayTrigger placement="right" overlay={<Tooltip>Chat</Tooltip>}>
                        <i className="icon material-symbols-outlined">
                            message
                        </i>
                        </OverlayTrigger>
                        <span className="item-name">Chat</span>
                    </Link>
                </Accordion.Item>


                <Accordion.Item as="li" eventKey="profile-menu" bsPrefix={`nav-item ${active === 'profile' ? 'active' : '' } `} onClick={() => setActive('profile')} >
                <Link className={`${location.pathname === '/dashboard/app/notification' ? 'active' : ''} nav-link `} aria-current="page"  to="/dashboard/app/notification">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Notification</Tooltip>}>
                        <i className="icon material-symbols-outlined">
                            notifications
                        </i>
                        </OverlayTrigger>
                        <span className="item-name">Notification</span>
                    </Link>
                   
                </Accordion.Item>


                <Accordion.Item as="li" eventKey="profile-menu" bsPrefix={`nav-item ${active === 'profile' ? 'active' : '' } `} onClick={() => handleShow1()} >
                <Link className={`${location.pathname === '/dashboard/app/notification' ? 'active' : ''} nav-link `} aria-current="page"  to="">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Create</Tooltip>}>
                        <i className="icon material-symbols-outlined">
                            Add
                        </i>
                        </OverlayTrigger>
                        <span className="item-name">Create</span>
                    </Link>
                   
                </Accordion.Item>

                <Accordion.Item as="li" eventKey="profile-menu" bsPrefix={`nav-item ${active === 'profile' ? 'active' : '' } `} onClick={() => setActive('profile')} >
                <Link className={`${location.pathname === '/dashboard/app/profile' ? 'active' : ''} nav-link`} to="/dashboard/app/profile">
                                    {/* <i className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="currentColor">
                                            <g>
                                                <circle cx="12" cy="12" r="8" fill="currentColor"></circle>
                                            </g>
                                        </svg>
                                    </i> */}
                                    <i className="icon material-symbols-outlined">
                                    account_box
                            </i>
                                    <OverlayTrigger placement="right" overlay={<Tooltip>Profile</Tooltip>}>
                                     <i className="sidenav-mini-icon"> P </i>
                                    </OverlayTrigger>
                                    <span className="item-name"> Profile </span>
                                </Link>
                   
                </Accordion.Item>

                
                
                <Nav.Item as="li">
                    
                </Nav.Item>
                 
            </Accordion>




<CreatePost  handleClose={handleClose1} show={show1} CustomToggle={CustomToggle}/>


            <Modal
                show={show}
                onHide={handleClose}
                className="search-modal mt-5"
                id="post-modal"
              >
                <div className="modal-dialog modal-fullscreen-lg-down m-0">
                  
                  <Modal.Header className="py-2">
                    <div className="d-flex align-items-center justify-content-between d-lg-none w-100">
                      <form
                        action="#"
                        className="searchbox w-50"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalFullscreenSm"
                        onClick={handleShow}
                      >
                        <Link className="search-link" to="/">
                          <span className="material-symbols-outlined">
                            search
                          </span>
                        </Link>

                        <Form.Control
                          type="text"
                          className="text search-input bg-soft-primary"
                          placeholder="Search here..."
                        />
                      </form>

                      <Link
                        to="/"
                        className="material-symbols-outlined text-dark"
                        onClick={handleClose}
                      >
                        close
                      </Link>
                    </div>
                    {/* <Modal.Title> */}
                    <div className="d-flex align-items-center justify-content-between ms-auto w-100">
                      <h5 className=" h4" id="exampleModalFullscreenLabel">
                        Recent
                      </h5>

                      <Link to="/" className="text-dark">
                        Clear All
                      </Link>
                    </div>
                    {/* </Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body className="p-0">
                    <div className="d-flex d-lg-none align-items-center justify-content-between w-100 p-3 pb-0">
                      <h5 className=" h4" id="exampleModalFullscreenLabel">
                        Recent
                      </h5>

                      <Link to="/" className="text-dark">
                        Clear All
                      </Link>
                    </div>
                    <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user6}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Paige Turner
                        </Link>

                        <span>Paige001</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Follow</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center border-bottom search-hover py-2 px-3">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user7}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Monty Carlo
                        </Link>

                        <span>Carlo.m</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Unfollow</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center search-hover py-2 px-3 border-bottom">
                      <div className="flex-shrink-0">
                        <Image
                          className="align-self-center img-fluid avatar-50 rounded-pill"
                          src={user8}
                          alt=""
                          loading="lazy"
                        />
                      </div>

                      <div className="d-flex flex-column ms-3">
                        <Link to="/" className="h5">
                          Paul Molive
                        </Link>

                        <span>Paul.45</span>
                      </div>

                      <div className="d-flex align-items-center ms-auto">
                        <Link to="/" className="me-3 d-flex align-items-center">
                          <small>Request</small>{" "}
                        </Link>

                        <Link
                          to="/"
                          className="material-symbols-outlined text-dark"
                        >
                          close
                        </Link>
                      </div>
                    </div>
                    <div className="">
                      <h4 className="px-3 py-2">Suggestions</h4>

                      <div className="suggestion-card px-3 d-flex">
                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user8}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Ammy Paul
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user9}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Roger Carlo
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story ">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user10}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Justin Molive
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile ">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user11}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Roy Fisher
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Request</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user12}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Johan Carlo
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user13}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              MedrLink Miles
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user14}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Aohan Paul
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Request</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user15}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Rokni Joy
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Follow</small>{" "}
                          </Link>
                        </div>

                        <div className="text-center story">
                          <div className="story-profile">
                            <Image
                              className="avatar-50 rounded-pill"
                              src={user16}
                              alt=""
                              loading="lazy"
                            />

                            <Link
                              to="/"
                              className="h6 mt-0 mt-lg-2 ms-3 ms-lg-0 text-ellipsis short-2 small"
                            >
                              Sepid Ryan
                            </Link>
                          </div>

                          <Link
                            to="/"
                            className="d-lg-none align-items-center d-flex"
                          >
                            <small>Unfollow</small>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
        </React.Fragment>
    )
})

export default VerticalNav