import axios from "axios"
import { BaseUrl } from "../../config"
 

export const getAllroles=async()=>{
 
     try {
         const resoponse = await axios.get(`${BaseUrl}roles/getall?page_number=1&page_size=100`)
         return resoponse
     } catch (error) {
       console.log(error) 
       throw error;   
     }
 }



 export const getbtIDroles=async(data)=>{
  const {id}=data
 
  try {
      const resoponse = await axios.get(`${BaseUrl}roles/getById/${id}`)
      return resoponse?.data
  } catch (error) {
    console.log(error) 
    throw error;   
  }
}
 
