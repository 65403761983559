import React from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import user21 from '../../../../assets/images/user/01.jpg'
import { Link } from 'react-router-dom'

const InviteFriend = () => {
  return (
    <div> <Card>
    <div className="card-header d-flex justify-content-between">
        <div className="header-title">
            <h4 className="card-title">Invite Friends</h4>
        </div>
    </div>
    <Card.Body  className='scroller WebScroller'>
    <React.Fragment >
                  <h6 className='text-success'>224 members</h6>
                  {Array.from({ length: 10 }, (_, index) => (
<>
    <Row key={index} className='my-2'>
      <Col md="2" className="col-2 title">
        <img style={{ width:'29px', borderRadius: '25px' }} src={user21} alt='' />
      </Col>
      <Col md="5" className="col-5 title">+1 {index}99 8{index}83 33{index}</Col>
      <Col md="4" className="col-4 title">
        <div>
          <Link to="#" className="chat-icon-phone bg-soft-primary d-flex justify-content-center align-items-center">
            Invite
          </Link>
        </div>
      </Col>
    </Row>
      <hr />
</>
  ))}
   <Row  >
      <h5 className='text-success '>View all(214 more)</h5>
    </Row>
 
        </React.Fragment>

    </Card.Body>
</Card></div>
  )
}

export default InviteFriend