import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { getAllroles, getbtIDroles } from "./Api";


 
 
 
 
const initialState = {
  roles: [],
  mode: {},

  loading: false,
  error: null,
};



const AsyncFunctionThunk = (name, apiFunction) => {
  return createAsyncThunk(
    `role/${name}`,
    async (data) => {
      try {
        const response= await apiFunction(data);
        return response.data;
      } catch (error) {
        console.error(`Failed to ${name}:`, error);
        throw error;
      }
    }
  );
};
 
export const fetchroles = AsyncFunctionThunk('fetchroles', getAllroles);
export const getbtIDmode = AsyncFunctionThunk('getbtIDroles', getbtIDroles);

 
const Roleslice = createSlice({
  name: 'Roleslice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchroles.fulfilled, (state, action) => {
        
        state.roles = action.payload;

        // state.roless.push(...action.payload);
        state.loading = false;
      })
      .addCase(fetchroles.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchroles.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getbtIDmode.fulfilled, (state, action) => {
        console.log(action.payload)
        state.mode = action.payload;

        // state.roless.push(...action.payload);
        state.loading = false;
      })
      .addCase(getbtIDmode.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getbtIDmode.rejected, (state, action) => {
        state.loading = false;
      })
    
  },
});

export default Roleslice.reducer;
