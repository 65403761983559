import React,{useState} from 'react'
import { Container, Row, OverlayTrigger, Tooltip, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ReactFsLightbox from 'fslightbox-react';
import img9 from '../../../assets/images/page-img/profile-bg9.jpg'

//profile-header
import ProfileHeader from '../../../components/profile-header'
import img51 from '../../../assets/images/page-img/51.jpg'
import img52 from '../../../assets/images/page-img/52.jpg'
import img53 from '../../../assets/images/page-img/53.jpg'
import img54 from '../../../assets/images/page-img/54.jpg'
import img55 from '../../../assets/images/page-img/55.jpg'
import img56 from '../../../assets/images/page-img/56.jpg'
import img57 from '../../../assets/images/page-img/57.jpg'
import img58 from '../../../assets/images/page-img/58.jpg'
import img59 from '../../../assets/images/page-img/59.jpg'
import img60 from '../../../assets/images/page-img/60.jpg'
import video from '../../../assets/images/plugins/video-2.mp4'
import video1 from '../../../assets/images/plugins/video-3.mp4'
import video2 from '../../../assets/images/plugins/video-1.mp4'
import imgn1 from '../../../assets/images/page-img/n1.jpg'
import imgn2 from '../../../assets/images/page-img/n2.jpg'
import imgn3 from '../../../assets/images/page-img/n3.jpg'
import imgn4 from '../../../assets/images/page-img/n4.jpg'
import imgn5 from '../../../assets/images/page-img/n5.jpg'
import imgn6 from '../../../assets/images/page-img/n6.jpg'
import imgn7 from '../../../assets/images/page-img/n7.jpg'
import imgn8 from '../../../assets/images/page-img/n8.jpg'
import imgr1 from '../../../assets/images/page-img/r1.jpg'
import imgr2 from '../../../assets/images/page-img/r2.jpg'
import imgr3 from '../../../assets/images/page-img/r3.jpg'
import imgr4 from '../../../assets/images/page-img/r4.jpg'
import imgr5 from '../../../assets/images/page-img/r5.jpg'
import imgr6 from '../../../assets/images/page-img/r6.jpg'
import imgr7 from '../../../assets/images/page-img/r7.jpg'
import imgr8 from '../../../assets/images/page-img/r8.jpg'
import imgl1 from '../../../assets/images/page-img/l1.jpg'
import imgl2 from '../../../assets/images/page-img/l2.jpg'
import imgl3 from '../../../assets/images/page-img/l3.jpg'
import imgl4 from '../../../assets/images/page-img/l4.jpg'
import imgl5 from '../../../assets/images/page-img/l5.jpg'
import imgl6 from '../../../assets/images/page-img/l6.jpg'
import img48 from '../../../assets/images/page-img/48.jpg'
 
import Card from '../../../components/Card'
//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;

const Explore =() =>{
   const [imageController, setImageController] = useState({
      toggler: false,
      slide: 1
  });
  
  function imageOnSlide(number) {
      setImageController({
      toggler: !imageController.toggler,
      slide: number
      }); 
  }
    return(
        <>
            <FsLightbox
                toggler={imageController.toggler}
                sources={[video,video1,video2,video2,video1,video,video1,video2,video,video2,video,video1,video,video1,video2]}
                slide={imageController.slide}
            />
      
               <div id="content-page" className="content-page">
                  
                  <Container>
                  <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Explore</h4>
                                        </div>
                                        <div className="card-header-toolbar d-flex align-items-center">
                                            <Link to="#">View All</Link>
                                        </div>
                                    </Card.Header>
                  <Row>
                     
                     <Col lg="4" md="6">
                        <div className="user-images position-relative overflow-hidden mb-3">
                           <Link onClick={() => imageOnSlide(2)} to="#">
                           <img src={img54} className="img-fluid rounded" alt="Responsive"/>
                           </Link>
                           <div className="image-hover-data">
                              <div className="product-elements-icon">
                                 <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                    <li className="">
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 
                                          <i className="material-symbols-outlined md-14 ms-1">
                                             thumb_up
                                          </i> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 
                                          <span className="material-symbols-outlined  md-14 ms-1">
                                             chat_bubble_outline
                                          </span> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 
                                          <span className="material-symbols-outlined md-14 ms-1">
                                             forward
                                          </span>
                                       </Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                              <Link to="#" className="image-edit-btn material-symbols-outlined md-16">
                                 drive_file_rename_outline
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </Col>
                     <Col lg="4" md="6">
                        <div className="user-images position-relative overflow-hidden mb-3">
                           <Link onClick={() => imageOnSlide(3)} to="#">
                              <img src={img51} className="img-fluid rounded" alt="Responsive "/>
                           </Link>
                           <div className="image-hover-data">
                              <div className="product-elements-icon">
                                 <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                    <li className="">
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 
                                          <i className="material-symbols-outlined md-14 ms-1">
                                             thumb_up
                                          </i> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 
                                          <span className="material-symbols-outlined  md-14 ms-1">
                                             chat_bubble_outline
                                          </span> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 
                                          <span className="material-symbols-outlined md-14 ms-1">
                                             forward
                                          </span>
                                       </Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                              <Link to="#" className="image-edit-btn material-symbols-outlined md-16">
                                 drive_file_rename_outline
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </Col>
                     <Col lg="4" md="6">
                        <div className="user-images position-relative overflow-hidden mb-3">
                           <Link onClick={() => imageOnSlide(4)} to="#">
                              <img src={img52} className="img-fluid rounded" alt="Responsive"/>
                           </Link>
                           <div className="image-hover-data">
                              <div className="product-elements-icon">
                                 <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                    <li className="">
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 
                                          <i className="material-symbols-outlined md-14 ms-1">
                                             thumb_up
                                          </i> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 
                                          <span className="material-symbols-outlined  md-14 ms-1">
                                             chat_bubble_outline
                                          </span> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 
                                          <span className="material-symbols-outlined md-14 ms-1">
                                             forward
                                          </span>
                                       </Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                              <Link to="#" className="image-edit-btn material-symbols-outlined md-16">
                                 drive_file_rename_outline
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </Col>
                          
                     <Col sm="6">
                                <Card className="card-block card-stretch card-height">
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Recent Post</h4>
                                        </div>
                                        <div className="card-header-toolbar d-flex align-items-center">
                                            <Link to="#">View All</Link>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                            <Swiper  
                                                spaceBetween={15} 
                                                slidesPerView={3}
                                                autoplay={{
                                                "delay": 2000,
                                                "disableOnInteraction": false }}  
                                                >
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn1} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Sean Paul</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center" style={{width: "176.8px", marginRight: "15px"}}>
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn2} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Fetty Wap</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn3} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Brittany Howard</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn4} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Elton John</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                            <Link to="#">
                                                                <img src={imgn5} alt="music-thumb" className="img-fluid w-100"/>
                                                            </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Tierra Whack</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn6} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Trippie Redd</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn7} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Quincy Jones</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className=" text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn8} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Ciara</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                            </Swiper>
                                            <Swiper  
                                                spaceBetween={15} 
                                                slidesPerView={3}
                                                autoplay={{
                                                "delay": 2000,
                                                "disableOnInteraction": false }}  
                                                >
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn1} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Sean Paul</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center" style={{width: "176.8px", marginRight: "15px"}}>
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn2} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Fetty Wap</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn3} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Brittany Howard</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn4} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Elton John</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                            <Link to="#">
                                                                <img src={imgn5} alt="music-thumb" className="img-fluid w-100"/>
                                                            </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Tierra Whack</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn6} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Trippie Redd</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className="text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn7} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Quincy Jones</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                                <SwiperSlide className=" text-center">
                                                    <div className="music-thumbnail position-relative mb-3">
                                                        <Link to="#">
                                                            <img src={imgn8} alt="music-thumb" className="img-fluid w-100"/>
                                                        </Link>
                                                        <div className="play-btn">
                                                            <Link to="#">
                                                                <i className="ri-play-fill text-white"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <h6>Ciara</h6>
                                                    <p className="mb-0">Best Advice</p>
                                                </SwiperSlide>
                                            </Swiper>
                                    </Card.Body>
                                </Card>
                            </Col>
                     <Col lg="6" md="6">
                        <div className="user-images position-relative overflow-hidden mb-3">
                           <Link onClick={() => imageOnSlide(5)} to="#">
                              <img src={img55} className="img-fluid rounded" alt="Responsive"/>
                           </Link>
                           <div className="image-hover-data">
                              <div className="product-elements-icon">
                                 <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                    <li className="">
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 
                                          <i className="material-symbols-outlined md-14 ms-1">
                                             thumb_up
                                          </i> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 
                                          <span className="material-symbols-outlined  md-14 ms-1">
                                             chat_bubble_outline
                                          </span> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 
                                          <span className="material-symbols-outlined md-14 ms-1">
                                             forward
                                          </span>
                                       </Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                              <Link to="#" className="image-edit-btn material-symbols-outlined md-16">
                                 drive_file_rename_outline
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </Col>
                     <Col lg="4" md="6">
                        <div className="user-images position-relative overflow-hidden mb-3">
                           <Link onClick={() => imageOnSlide(6)} to="#">
                              <img src={img56} className="img-fluid rounded" alt="Responsive "/>
                           </Link>
                           <div className="image-hover-data">
                              <div className="product-elements-icon">
                                 <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                    <li className="">
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 
                                          <i className="material-symbols-outlined md-14 ms-1">
                                             thumb_up
                                          </i> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 
                                          <span className="material-symbols-outlined  md-14 ms-1">
                                             chat_bubble_outline
                                          </span> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 
                                          <span className="material-symbols-outlined md-14 ms-1">
                                             forward
                                          </span>
                                       </Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                              <Link to="#" className="image-edit-btn material-symbols-outlined md-16">
                                 drive_file_rename_outline
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </Col>
                     <Col lg="4" md="6">
                        <div className="user-images position-relative overflow-hidden mb-3">
                           <Link onClick={() => imageOnSlide(7)} to="#">
                              <img src={img57} className="img-fluid rounded" alt="Responsive "/>
                           </Link>
                           <div className="image-hover-data">
                              <div className="product-elements-icon">
                                 <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                    <li className="">
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 
                                          <i className="material-symbols-outlined md-14 ms-1">
                                             thumb_up
                                          </i> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 
                                          <span className="material-symbols-outlined  md-14 ms-1">
                                             chat_bubble_outline
                                          </span> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 
                                          <span className="material-symbols-outlined md-14 ms-1">
                                             forward
                                          </span>
                                       </Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                              <Link to="#" className="image-edit-btn material-symbols-outlined md-16">
                                 drive_file_rename_outline
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </Col>
                     <Col lg="4" md="6">
                        <div className="user-images position-relative overflow-hidden mb-3">
                           <Link onClick={() => imageOnSlide(5)} to="#">
                              <img src={img55} className="img-fluid rounded" alt="Responsive"/>
                           </Link>
                           <div className="image-hover-data">
                              <div className="product-elements-icon">
                                 <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                    <li className="">
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 
                                          <i className="material-symbols-outlined md-14 ms-1">
                                             thumb_up
                                          </i> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 
                                          <span className="material-symbols-outlined  md-14 ms-1">
                                             chat_bubble_outline
                                          </span> 
                                       </Link>
                                    </li>
                                    <li>
                                       <Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 
                                          <span className="material-symbols-outlined md-14 ms-1">
                                             forward
                                          </span>
                                       </Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                              <Link to="#" className="image-edit-btn material-symbols-outlined md-16">
                                 drive_file_rename_outline
                              </Link>
                           </OverlayTrigger>
                        </div>
                     </Col>
                  </Row>
               </Container>
               </div>
        </>
  )

}

export default Explore;