import React,{useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown ,Modal} from 'react-bootstrap'
 

import Card from '../../../../components/Card'
import CustomToggle from '../../../../components/dropdowns'
import EmojiPicker  from 'emoji-picker-react';
import user1 from '../../../../assets/images/user/1.jpg'
 import user10 from '../../../../assets/images/user/01.jpg'


 

const StarredMessage = ({ show }) => {
 
  
 
   
 
  return (
    <Tab.Pane eventKey='ten' className={`fade ${show ? 'show' : ''}`} id='user10' role="tabpanel">
    <div className="chat-head">
      <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
        <div className="d-flex align-items-center">
          <div className="sidebar-toggle">
            <i className="ri-menu-3-line"></i>
          </div>
          <div className="avatar chat-user-profile m-0 me-3">
            <img loading="lazy" src={user1} alt="avatar" className="avatar-50"   />
            <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span>
          </div>
          <h5 className="mb-0">Starred Message</h5>
        </div>
         
      </header>


      <div className='scroller WebScroller90'  >
                 
                  {Array.from({ length: 10}, (_, index) => (
<>
<div key={index} className={`chat d-flex   chat-left `}>
            <div className="chat-user">
              <Link className="avatar m-0" to="">
                <img loading="lazy" src={user10} alt="avatar" className="avatar-35" />
              </Link>
              <span className="chat-time mt-1">{index+1}:00</span>
            </div>
            <div className="chat-detail">
              <div className="chat-message">
                <p>  <i class="ri-star-line"></i>  hello{index}</p>
              </div>
            </div>
          </div>
      <hr />
</>
  ))}
   <Row  >
      <h5 className='text-success '>View all(214 more)</h5>
    </Row>
    <div className='empty20 ' >
     
     </div>
        </ div>

    </div>

     
</Tab.Pane>
  );
};


export default StarredMessage