import React,{ useState, useRef } from 'react'
import Footer from './Footer';
import Logo from './Logo';
import Sidebar from './Sidebar';
import './Video.css'
import video from './video-1.mp4'


function Video({video}) {
    const [playing, setPlaying] = useState(false);
    const videoRef = useRef(null);
    const onVideoPress = ()=>{
        if (playing) {
            videoRef.current.pause();
            setPlaying(false);
          } else {
            videoRef.current.play();
            setPlaying(true);
          }
    }
    return (
        <div className="video">
            <Logo/>

            <video src={"https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4"} height='100%' className=" " muted  autoPlay loop></video>

            
            <Sidebar like={80} messages={12}/>
            <Footer channel={'user'} description={'video description'} music={'video?.song'}/>
        </div>
    )
}

export default Video
