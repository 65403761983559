import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Modal, Row } from 'react-bootstrap'

const RemoveChat = ({ onHide, show, profile }) => {
   

    const endCall = () => {
        
        onHide();
    };

 

    return (
        <Modal size="md" className="fade" id="post-modal" onHide={onHide} show={show}>
            <Modal.Header className="d-flex justify-content-center">
                <div className="user mb-4 text-center">
                 
                    <div className="user-name mt-4">
                        <h4>Remove Chat</h4>
                    </div>
                   
                </div>
            </Modal.Header>
            <Modal.Body>
                <div  className="text-center">
                    <p> Are you sure you want to delete this chat?</p>
                </div>
                <Row>
               
                <Col md="6" className="col-6 title"> <button type="submit" className="btn btn-primary d-block w-50 mt-3 mx-auto" onClick={endCall}> Cancel</button> </Col>
  
                
            <Col md="6" className="col-6 title"> <button type="submit" className="btn btn-danger d-block w-50 mt-3 mx-auto" onClick={endCall}>  Delete</button></Col>
                </Row>

                
            </Modal.Body>
        </Modal>
    )
}

export default RemoveChat
