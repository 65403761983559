import React from 'react';
 

const Numpad = ({amount,setAmount}) => {
  const buttons = [
    { label: '1', type: 'primary' },
    { label: '2', type: 'primary' },
    { label: '3', type: 'primary' },
    { label: '4', type: 'primary' },
    { label: '5', type: 'primary' },
    { label: '6', type: 'primary' },
    { label: '7', type: 'primary' },
    { label: '8', type: 'primary' },
    { label: '9', type: 'primary' },
    { label: 'Clear', type: 'secondary' },
    { label: '0', type: 'primary' },
    { label: 'Next', type: 'success' },
  ];

  const change=(value)=>{
    if (value === 'Clear'|| value === 'Next' ) {
        setAmount('')
    }
    else{

        setAmount((prev)=>prev+value)
        }
  }
  return (
    <div className="  numpad-container ">
      <div className="row justify-content-around">
        {buttons.map((button, index) => (
          <div className="col-4  mb-2" key={index} onClick={()=>change(button.label)}>
            <button className={`btn btn-${button.type} numpad-button`}>
              {button.label}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

 

export default Numpad;
