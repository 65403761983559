import React, { useEffect } from 'react'

//header
import Header from '../../components/partials/dashboard/HeaderStyle/header'

//sidebar
import RightSidebar from '../../components/partials/dashboard/SidebarStyle/rightsidebar'

//sidebar
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar'

//footer
import Footer from '../../components/partials/dashboard/FooterStyle/footer'

//default 
// import DefaultRouter from '../../router/default-router'

// share-offcanvas
import WithAuth from '../../ProtectedRoutes'

//settingoffCanvas
import SettingOffCanvas from '../../components/setting/SettingOffCanvas'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { theme_color } from '../../store/setting/actions'

const Default = () => {
    
   
   
    return (
        <>

                <Sidebar />
                <Header />
                <div className="main-content">
                <Outlet/>
                </div>
                <RightSidebar />    
            <Footer />
            
        
 </>
    )
}

export default Default
