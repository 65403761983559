import React from "react";
import Default from "../layouts/dashboard/default";
 

import { DefaultRouter } from "./default-router";
import { Layout1Router } from "./layout1-router";
import { SimpleRouter } from "./simple-router";
import WithAuth from "../ProtectedRoutes";
import SignIn from "../views/dashboard/auth/sign-in";
import { ChatRouter } from "./chat-router";

export const IndexRouters = [
  {
    path: "/",
    element: <WithAuth />, // Protect the entire Default layout
    children: [
      {
        path: "/",
        element: <Default />,
        children: [
          ...DefaultRouter,
          ...Layout1Router,
          
        ],
      },
    ],
  },
  
];