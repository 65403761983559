import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Modal, Row } from 'react-bootstrap'

const VideoCall = ({ onHide, show, profile }) => {
    const [timing, setTiming] = useState('Ringing.....')
    const [timer, setTimer] = useState(50)

    useEffect(() => {
        let timerInterval;
        if (timing === 'Ringing.....') {
            const ringingTimeout = setTimeout(() => {
                setTiming('active');
                setTimer(0);
            }, 5000);

            return () => clearTimeout(ringingTimeout);
        } else if (timing === 'active') {
            timerInterval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }

        return () => clearInterval(timerInterval);
    }, [timing]);

    const endCall = () => {
        setTiming('Ringing.....');
        setTimer(0);
        onHide();
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    return (
        <Modal size="md" className="fade" id="post-modal" onHide={onHide} show={show}>
            <Modal.Header className="d-flex justify-content-center">
                <div className="user mb-4 text-center">
                <p>Video Call</p>

                    <Link className="avatar m-0" to="">
                        <img loading="lazy" src={profile?.avatar} alt="avatar" />
                    </Link>
                    <div className="user-name mt-4">
                        <h4>{profile?.name}</h4>
                    </div>
                    <div className="user-desc">
                        <p>{profile?.location}</p>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div style={{ height: '30vh' }} className="text-center">
                    <p>{timing === "Ringing....." ? timing : `${formatTime(timer)} - Sec`}</p>
                </div>
                <Row>
                <Col md="4" className="col-4 title"> <div>  <Link to="#" className="chat-icon-phone p-3 bg-soft-primary d-flex justify-content-center align-items-center">
              <i className="material-symbols-outlined md-18">mic_off</i>Mute 
            </Link></div></Col>
            <Col md="4" className="col-4 title">  </Col>
            <Col md="4" className="col-4 title"> <div>  <Link to="#" className="chat-icon-phone p-3 bg-soft-primary d-flex justify-content-center align-items-center">
              <i className="material-symbols-outlined md-18">videocam_off</i>Video 
            </Link></div></Col>
                </Row>
                <button type="submit" className="btn btn-danger d-block w-50 mt-3 mx-auto" onClick={endCall}>  End Video Call</button>

                
            </Modal.Body>
        </Modal>
    )
}

export default VideoCall
