import React,{useEffect, useRef, useState} from 'react'
 
 
 


 
 import { Card, Form } from 'react-bootstrap'
  
//image
import * as SettingSelector from '../../../../store/setting/selectors'
import ThemeScheme from '../../../../components/setting/sections/theme-scheme'
import { useSelector } from 'react-redux'

const ThemeSetting = ({ show }) => {
 
  
 
  const themeScheme = useSelector(SettingSelector.theme_scheme)
 





  return (
    <div> <Card>
    <div className="card-header d-flex justify-content-between">
        <div className="header-title">
            <h4 className="card-title">Theme</h4>
        </div>
    </div>
    <Card.Body>
    <ThemeScheme themeScheme={themeScheme}></ThemeScheme>
    </Card.Body>
</Card></div>
  )
}

 
export default ThemeSetting