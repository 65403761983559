import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import Leaflet from '../../../../components/leaflet';

const LocationTab = ({ onHide, show, profile }) => {
   

    const endCall = () => {
        
        onHide();
    };

 

    return (
        <Modal size="md" className="fade" id="post-modal" onHide={onHide} show={show}>
            <Modal.Header className="d-flex justify-content-center">
                <div className="user text-center">
                 
                    <div className="user-name ">
                        <h4>Location</h4>
                    </div>
                   
                </div>
            </Modal.Header>
            <Modal.Body>
            <Col lg="12">
                        <Card className="card-block card-stretch card-height mt-3">
                           <Card.Body className="p-0">
                              <Leaflet id="chart-map-column-04" className="custom-chart"/>
                           </Card.Body>
                        </Card>
                     </Col>
                <Row>
                <ul className="media-story list-inline  m-0 p-0"  >
                <li className="d-flex mb-3 align-items-center"  >
                <i class="ri-map-pin-user-line"></i>
                                       
                                        <div className="stories-data ms-3">
                                            <h5>Share live location</h5>
                                          
                                        </div>
                                    </li>
                                    <hr></hr>
                                    <li className="d-flex mb-3 align-items-center"  >
                                    <i class="ri-at-fill"></i>
                                       
                                        <div className="stories-data ms-3">
                                            <h5>Send your current location</h5>
                                            <p className="mb-0">Accurate to 13 meters</p>
                                        </div>
                                    </li>
               </ul>
                </Row>

                
            </Modal.Body>
        </Modal>
    )
}

export default LocationTab
