import React,{ useState,useEffect } from 'react';
import './App.css'
import Video from './Video';
 

function VideoPage() {
  const [videos, setVideos] = useState([]);
 
   
  return (
    <div className="app">
        <div className="shorts_vedios">
        
        {Array.from({ length: 10}).map((_, index) => (
          <Video key={`video${index}`} />
        ))}
        
        </div>
    </div>
  );
}

export default VideoPage;
