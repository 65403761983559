import React from 'react'
import {Card, Dropdown, Image} from 'react-bootstrap'

//image
import user1 from '../../../../assets/images/user/01.jpg'
import user2 from '../../../../assets/images/user/02.jpg'
import user3 from '../../../../assets/images/user/03.jpg'
import user4 from '../../../../assets/images/user/04.jpg'
import user5 from '../../../../assets/images/user/11.jpg'
import user6 from '../../../../assets/images/user/12.jpg'
// import s1 from '../../assets/images/page-img/s1.jpg'
import s1 from '../../../../assets/images/page-img/s1.jpg'

import s2 from '../../../../assets/images/page-img/s2.jpg'
import s3 from '../../../../assets/images/page-img/s3.jpg'
import s4 from '../../../../assets/images/page-img/s4.jpg'
import s5 from '../../../../assets/images/page-img/s5.jpg'
import { Link } from 'react-router-dom'
import CustomToggle from '../../../dropdowns'

const RightSidebar = () => {
    const minirightsidebar =() =>{
        document.getElementById('rightSidebar').classList.toggle('right-sidebar');
        document.body.classList.toggle('right-sidebar-close');
    }
    return (
        <>
            <div className="right-sidebar-mini scroller" id="rightSidebar">
                <div className="right-sidebar-panel p-0">
                <Card>
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Stories</h4>
                                </div>
                            </div>
                            <Card.Body>
                                <ul className="media-story list-inline m-0 p-0">
                                    <li className="d-flex mb-3 align-items-center">
                                        <i className="ri-add-line"></i>
                                        <div className="stories-data ms-3">
                                            <h5>Creat Your Story</h5>
                                            <p className="mb-0">time to story</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center active">
                                        <img src={s2} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                            <h5>Anna Mull</h5>
                                            <p className="mb-0">1 hour ago</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center">
                                        <img src={s3} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                            <h5>Ira Membrit</h5>
                                            <p className="mb-0">4 hour ago</p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <img src={s1} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                            <h5>Bob Frapples</h5>
                                            <p className="mb-0">9 hour ago</p>
                                        </div>
                                    </li>
                                </ul>
                                <Link to="#" className="btn btn-primary d-block mt-3">See All</Link>
                            </Card.Body>
                        </Card>
                        <Card>
                            <div className="card-header d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Suggested for you</h4>
                                </div>
                                <div className="card-header-toolbar d-flex align-items-center">
                                <Link to="#" className="  d-block mt-3">See All</Link>
                                </div>
                            </div>
                            <Card.Body>
                                <ul className="media-story list-inline m-0 p-0">
                                <li className="d-flex mb-3 align-items-center active">
                                        <img src={s2} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                            <h5>Anna Mull</h5>
                                            <p className="mb-0" style={{color:'#0a66c2'}}>Follow</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center active">
                                        <img src={s2} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                            <h5>Anna Mull</h5>
                                            <p className="mb-0" style={{color:'#0a66c2'}}>Follow</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center active">
                                        <img src={s2} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                            <h5>Anna Mull</h5>
                                            <p className="mb-0" style={{color:'#0a66c2'}}>Follow</p>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center active">
                                        <img src={s2} alt="story-img" className="rounded-circle img-fluid"/>
                                        <div className="stories-data ms-3">
                                            <h5>Anna Mull</h5>
                                            <p className="mb-0" style={{color:'#0a66c2'}}>Follow</p>
                                        </div>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            
        </>
    )
}

export default RightSidebar
