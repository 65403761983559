import React,{useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Form, Tab, Nav, Button,Dropdown ,Modal} from 'react-bootstrap'
 

import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import EmojiPicker  from 'emoji-picker-react';
import user1 from '../../../assets/images/user/1.jpg'
 import user10 from '../../../assets/images/user/01.jpg'


 

const StatusTab = ({ show }) => {
 
  
  
   
 
  return (
    <Tab.Pane eventKey='status' className={`fade ${show ? 'show' : ''}`} id='status' role="tabpanel">
    <div className="chat-head">
      <header className="d-flex justify-content-between align-items-center bg-white pt-3  ps-3 pe-3 pb-3">
        <div className="d-flex align-items-center">
          <div className="sidebar-toggle">
            <i className="ri-menu-3-line"></i>
          </div>
          <div className="avatar chat-user-profile m-0 me-3">
            <img loading="lazy" src={user1} alt="avatar" className="avatar-50"   />
            <span className="avatar-status"><i className="material-symbols-outlined text-success  md-14 filled">circle</i></span>
          </div>
          <h5 className="mb-0">Status</h5>
        </div>
         
      </header>


      <React.Fragment >
                 
      <div className="row justify-content-center align-items-center">
    <div className="col-4">
      <div className="card bg-dark text-white" style={{maxWidth:'28rem',height:'80vh'}}>
        <video src="https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4" height='100%' className="card-img-top" muted  autoPlay loop></video>

         
      </div>
    </div>
  </div>
        </React.Fragment>

    </div>

     
</Tab.Pane>
  );
};


export default StatusTab